<header>
  <nav class="navbar fixed-top">
    <div class="container">
      <a href="" routerLink="home" class="logo" title="home"></a>
      <ul class="list-unstyled float-right">
        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link" ngbDropdownToggle href="javascript:;" id="navbarPortfolioMenuLink">
            Apps
          </a>
          <div ngbDropdownMenu aria-labelledby="navbarPortfolioMenuLink">
            <a class="dropdown-item" href="https://radio-dutch.sofdzy.com">Radio[Dutch]
              <span class="text-muted">(WebOS app)</span>
            </a>
          </div>
        </li>
        <li>
          <a href="" routerLink="contact">Contact</a>
        </li>
      </ul>
    </div>
  </nav>
</header>
