import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { FormsModule }   from '@angular/forms';
import { RouterModule }  from '@angular/router';

import { ThumbsComponent } from './components/thumbs/thumbs.component';
import { BlockTextImageComponent } from './components/block-text-image/block-text-image.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule
  ],
  declarations: [
    ThumbsComponent,
    BlockTextImageComponent
  ],
  providers: [],
  exports: [
    CommonModule,
    FormsModule,
	  ThumbsComponent,
    BlockTextImageComponent,
    RouterModule
  ]
})
export class SharedModule { }
